<template>
  <v-container class="px-lg-10 py-lg-6">
    <v-responsive class="mx-auto">
      <breadcrumbs />
      <h2>{{ $t("error.404") }}</h2>
    </v-responsive>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Error404 extends Vue {}
</script>
